import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`이 교재는 프로그래밍 경험이 전무한 독자를 대상으로, 최대한 빠른 시간 내에 실무에서 사용되는 JavaScript 언어의 문법과 기능들을 습득할 수 있도록 제작했습니다.`}</p>
    <h2>{`이 교재의 특징`}</h2>
    <ul>
      <li parentName="ul">{`책을 여러 파트로 나누어서, 학습 단계별로 교재의 적절한 파트를 골라서 활용할 수 있도록 구성했습니다.`}</li>
      <li parentName="ul">{`최신 JavaScript의 문법 및 기능을 다루었습니다.`}</li>
    </ul>
    <h2>{`목차`}</h2>
    <h3>{`JavaScript 소개`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/020-tutorial.html"
        }}>{`튜토리얼`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/100-javascript.html"
        }}>{`JavaScript 소개`}</a></li>
    </ul>
    <h3>{`JavaScript 기초`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/120-value-variable-type.html"
        }}>{`값 다루기`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/130-number.html"
        }}>{`number 타입`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/140-string.html"
        }}>{`string 타입`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/150-boolean.html"
        }}>{`boolean 타입`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/160-null-undefined.html"
        }}>{`null과 undefined`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/170-function.html"
        }}>{`함수`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/175-control-statement.html"
        }}>{`제어 구문`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/180-object.html"
        }}>{`객체`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/190-array.html"
        }}>{`배열`}</a></li>
    </ul>
    <h3>{`JavaScript 심화 1`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/220-value-in-depth.html"
        }}>{`값 더 알아보기`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/230-function-in-depth.html"
        }}>{`함수 더 알아보기`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/255-fp.html"
        }}>{`함수형 프로그래밍`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/240-object-in-depth.html"
        }}>{`객체 더 알아보기`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/245-operator-in-depth.html"
        }}>{`연산자 더 알아보기`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/250-builtins.html"
        }}>{`내장 객체 및 생성자`}</a></li>
    </ul>
    <h3>{`JavaScript 심화 2`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/260-iteration.html"
        }}>{`Iterable`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/270-class.html"
        }}>{`클래스`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/282-data-structures.html"
        }}>{`큐, 스택, 트리`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/285-async.html"
        }}>{`비동기 프로그래밍`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/290-exception.html"
        }}>{`예외 처리`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/pages/293-module.html"
        }}>{`모듈`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      